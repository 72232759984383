export default {
  DashboardContainer: {
    vocabularies: [
      'global',
      'errors',
      'enums',
      'navbar',
      'general',
      'setup_assistant',
      'triggers',
      'setup_companies',
      'breadcrumb',
      'notif_tmpl_general',
      'forms',
      'entities',
      'search',
    ],
    lookups: [
      'global',
      'client',
    ],
  },
  // AuthContainer: {
  //   vocabularies: [
  //     'global',
  //     'enums',
  //   ],
  //   lookups: [
  //     'global',
  //   ],
  // },
  PrintContainer: {
    vocabularies: [
      'global',
      'enums',
      'forms',
      'notif_tmpl_general',
    ],
    lookups: [
      'client',
      'global',
    ],
  },
  MyProjects: {
    vocabularies: [
      'entity_list',
      'projects',
      'triggers',
      'search',
      'projects',
      'project',
      'notes',
      'activities',
      'global',
      'companies',
    ],
  },
  Project: {
    vocabularies: [
      'entity_list',
      'projects',
      'project',
      'company',
      'triggers',
      'projects',
      'companies',
      'tenders',
      'contacts',
      'activities',
      'notes',
      'activities',
      'search',
    ],
  },
  MyCompanies: {
    vocabularies: [
      'company',
      'companies',
      'entity_list',
      'triggers',
      'search',
      'companies',
      'notes',
      'activities',
    ],
  },
  Company: {
    vocabularies: [
      'entity_list',
      'company',
      'triggers',
      'project',
      'projects',
      'companies',
      'tenders',
      'contacts',
      'activities',
      'fields_company_related',
      'notes',
      'activities',
      'search',
      'company_analysis',
    ],
  },
  MyContacts: {
    vocabularies: [
      'contacts',
      'entity_list',
      'search',
      'contacts',
      'notes',
      'activities',
    ],
  },
  Contact: {
    vocabularies: [
      'entity_list',
      'contacts',
      'triggers',
      'project',
      'projects',
      'companies',
      'tenders',
      'contacts',
      'activities',
      'notes',
      'activities',
      'search',
    ],
  },
  MyTenders: {
    vocabularies: [
      'entity_list',
      'tenders',
      'global',
      'triggers',
      'search',
      'tenders',
      'notes',
      'activities',
      'project',
    ],
  },
  Tender: {
    vocabularies: [
      'entity_list',
      'tender',
      'project',
      'triggers',
      'projects',
      'companies',
      'tenders',
      'contacts',
      'activities',
      'notes',
      'activities',
      'company',
      'contacts',
      'global',
    ],
  },
  MyActivities: {
    vocabularies: [
      'projects',
      'project',
      'tenders',
      'companies',
      'contacts',
      'sales_activities',
      'activities',
      'activities',
      'search',
      'entity_list',
    ],
  },
  MyNotes: {
    vocabularies: [
      'projects',
      'project',
      'tenders',
      'companies',
      'contacts',
      'sales_notes',
      'notes',
      'search',
    ],
    lookups: ['client'],
  },
  AllProjects: {
    vocabularies: [
      'projects',
      'entity_list',
      'triggers',
      'search',
      'projects',
      'project',
      'notes',
      'activities',
      'companies',
    ],
  },
  AllCompanies: {
    vocabularies: [
      'companies',
      'entity_list',
      'triggers',
      'search',
      'companies',
      'notes',
      'activities',
    ],
  },
  AllContacts: {
    vocabularies: [
      'contacts',
      'entity_list',
      'search',
      'contacts',
      'notes',
      'activities',
    ],
  },
  AllTenders: {
    vocabularies: [
      'tenders',
      'entity_list',
      'triggers',
      'search',
      'tenders',
      'notes',
      'activities',
      'project',
    ],
  },
  Invite: {
    vocabularies: [
      'invite',
      'password',
      'global',
      'errors',
      'forms',
    ],
    lookups: [
      'global',
    ],
  },
  UserInit: {
    vocabularies: [
      'user_init',
      'user_profile',
      'global',
      'errors',
    ],
    lookups: [
      'user_profile',
    ],
  },
  Login: {
    lookups: [
      'global',
    ],
    vocabularies: [
      'login',
      'password',
      'errors',
      'invite',
      'notif_tmpl_general',
    ],
  },
  UserProfile: {
    vocabularies: [
      'user_profile',
      'client_profile',
      'errors',
      'password',
      'api_clients',
      'clients',
      'smart_selections',
      'system_modules'
    ],
    lookups: [
      'global',
      'user_profile',
    ],
  },
  SupportClients: {
    vocabularies: [
      'subscriptions',
      'triggers',
      'search',
      'client_profile',
      'clients',
      'users',
    ],
  },
  SupportClient: {
    vocabularies: [
      'client_profile',
      'clients',
      'users',
      'project',
      'tender',
      'fixed_addons',
      'entity_list',
      'custom_fieldset',
      'search',
      'subscriptions',
      'system_modules',
    ],
  },
  SupportEditorClient: {
    vocabularies: [
      'triggers',
      'search',
      'client_profile',
      'clients',
      'system_modules',
    ],
  },
  SupportUser: {
    vocabularies: [
      'user_profile',
      'triggers',
      'users',
      'myran_logs_user_profiles',
      'email_sender_logs',
      'email_sender_events',
      'fixed_addons',
      'user_transfer_tasks',
      'search',
      'subscriptions',
      'entity_list',
      'smart_selections',
      'system_modules',
    ],
    lookups: [
      'user_profile',
    ],
  },
  PrivacyCookiesPolicy: {
    vocabularies: [
      'general',
    ],
  },
  UsageStatisticsClients: {
    vocabularies: [
      'search',
      'usage_stats',
      'clients',
    ],
  },
  UsageStatisticsClient: {
    vocabularies: [
      'search',
      'usage_stats',
      'users',
    ],
  },
  UsageStatisticsUsers: {
    vocabularies: [
      'search',
      'usage_stats',
      'users',
    ],
  },
  UsageStatisticsUser: {
    vocabularies: [
      'usage_stats',
      'users',
    ],
  },
  PageError: {
    vocabularies: ['errors', 'breadcrumb'],
  },
  Page500: {
    vocabularies: ['errors', 'breadcrumb'],
  },
  Forbidden: {
    vocabularies: ['errors', 'breadcrumb'],
  },
  Page404: {
    vocabularies: ['errors', 'breadcrumb'],
  },
  ClientAccessDenied: {
    vocabularies: ['errors', 'breadcrumb'],
  },
  SystemDefaultFieldsets: {
    vocabularies: [
      'default_fieldsets',
      'search',
      'projects',
      'tenders',
      'companies',
      'contacts',
    ],
  },
  OAuth: {
    lookups: [
      'global',
    ],
    vocabularies: [
      'login',
      'oauth',
    ],
  },
  PageNetworkError: {
    vocabularies: [
      'errors',
    ],
  },
  SupportWebhook: {
    vocabularies: [
      'webhooks',
    ],
  },
  SettingsWebhook: {
    vocabularies: [
      'webhooks',
    ],
  },
  SupportWebhookQueue: {
    vocabularies: [
      'webhooks',
      'webhook_queue',
    ],
  },
  UserWebhookQueue: {
    vocabularies: [
      'webhooks',
      'webhook_queue',
    ],
  },
  ApiClients: {
    vocabularies: [
      'api_clients',
      'search',
    ],
  },
  SupportApiClient: {
    vocabularies: [
      'api_clients',
      'fixed_addons',
      'system_modules',
    ],
  },
  Users: {
    vocabularies: [
      'users',
      'entity_list',
      'search',
      'subscriptions',
    ],
  },
  MyranLogsUserProfiles: {
    vocabularies: [
      'myran_logs_user_profiles',
    ],
  },
  FileDownload: {
    vocabularies: [
      'file_download',
      'errors',
      'global',
    ],
  },
  MyFiles: {
    vocabularies: [
      'user_files',
      'search',
    ],
  },
  SupportFiles: {
    vocabularies: [
      'user_files',
    ],
  },
  SupportReleaseNotes: {
    vocabularies: [
      'search',
      'release_notes'
    ],
  },
  SupportReleaseNote: {
    vocabularies: [
      'release_notes'
    ],
  },
  VortalConnectContacts: {
    vocabularies: [
      'vortal_connect_contacts'
    ],
  },
  VortalConnectCompanies: {
    vocabularies: [
      'vortal_connect_companies'
    ],
  },
  VortalConnectProjects: {
    vocabularies: [
      'vortal_connect_projects'
    ],
  },
  VortalConnectNotificationEmails: {
    vocabularies: [
      'vortal_connect_notif_emails'
    ],
  },
  VortalConnectLogs: {
    vocabularies: [
      'vortal_connect_logs'
    ],
  },
  SupportEmailLogs: {
    vocabularies: [
      'email_sender_logs',
      'email_sender_events'
    ],
  },
  SupportSSNList: {
    vocabularies: [
      'saved_search_notifs',
      'users',
    ],
  },
  SupportUserTransferTasks: {
    vocabularies: [
      'search',
      'user_transfer_tasks',
    ],
  },
  SystemDeadMessages: {
    vocabularies: [
      'dead_messages',
      'search',
      'dead_message_subscriptions',
    ],
  },
  SystemDeadMessageEditor: {
    vocabularies: [
      'dead_messages'
    ],
  },
  SystemDeadMessageSubscriptions: {
    vocabularies: [
      'dead_message_subscriptions',
      'search',
    ],
  },
  SystemDeadMessageSubscriptionEditor: {
    vocabularies: [
      'dead_message_subscriptions'
    ],
  },
  SystemComponents: {
    vocabularies: [
      'system_components'
    ],
  },
  SystemModules: {
    vocabularies: [
      'system_modules'
    ],
  },
  SystemCustomFieldMetrics: {
    vocabularies: [
      'custom_field_metrics'
    ],
  },
  SystemCustomFieldMetricEditor: {
    vocabularies: [
      'custom_field_metrics'
    ],
  },
  SystemCustomFieldSelectionMetrics: {
    vocabularies: [
      'custom_field_metrics'
    ],
  },
  SystemCustomFieldSelectionMetricEditor: {
    vocabularies: [
      'custom_field_metrics'
    ],
  },
  ParabygMigration: {
    vocabularies: [
      'parabyg_migration',
      'entity_list',
    ],
  },
  SystemCustomFieldsetTemplates: {
    vocabularies: [
      'entity_list',
      'custom_fieldset',
    ],
  },
  SystemErrorMessages: {
    vocabularies: [
      'entity_list',
      'error_messages',
    ],
  },
  CompanyAnalysis: {
    vocabularies: [
      'global',
      'company_analysis',
      'fields_company_related',
      'company_analysis_widget',
    ],
    lookups: [
      'client',
      'global',
    ],
  },
  SupportSubscriptionEdit: {
    vocabularies: [
      'clients',
      'subscriptions',
      'smart_selections',
      'system_modules',
    ],
  },
  SupportSubscriptions: {
    vocabularies: [
      'subscriptions',
      'search',
      'entity_list',
      'users',
    ],
  },
  SupportSubscription: {
    vocabularies: [
      'subscriptions',
      'clients',
      'users',
      'smart_selections',
      'search',
      'entity_list',
      'system_modules',
    ],
  },
  SupportClientUserEditor: {
    lookups: [
      'user_profile',
    ],
    vocabularies: [
      'users',
      'entity_list',
      'search',
      'system_modules',
      'clients',
    ],
  },
  Dashboard: {
    vocabularies: [
      'dashboard',
      'projects',
      'companies',
      'notes',
      'activities',
    ],
  },
  SystemDefaultDashboardWidgets: {
    vocabularies: [
      'dashboard',
      'default_dashboard_widgets',
    ],
  },
  SystemCountryModules: {
    vocabularies: [
      'country_modules',
    ],
  },
};
